






















































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo70',
})
export default class Logo70 extends Vue {}
